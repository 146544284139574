import axios from "axios";
import { loginConfirmedAction, Logout } from "../../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN } from "./AuthEndPoints";
import axiosInstance from "../AxiosInstance";
import { notifyError } from "../../jsx/components/Common/Toaster";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.post(LOGIN, postData);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function dashBoard() {
  return axiosInstance.get(DASHBOARD);
}
export function formatError(errorResponse) {
  // console.log(errorResponse, "error in auth");
  notifyError(errorResponse);

  // switch (errorResponse.error.message) {
  //   case "EMAIL_EXISTS":
  //     //return 'Email already exists';
  //     swal("Oops", "Email already exists", "error");
  //     break;
  //   case "EMAIL_NOT_FOUND":
  //     //return 'Email not found';
  //     swal("Oops", "Email not found", "error", { button: "Try Again!" });
  //     break;
  //   case "INVALID_PASSWORD":
  //     //return 'Invalid Password';
  //     swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
  //     break;
  //   case "USER_DISABLED":
  //     return "User Disabled";

  //   default:
  //     return "";
  // }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("huddleToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("huddleToken");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
export function isLogin() {
  const tokenDetailsString = localStorage.getItem("huddleToken");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
