import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  createPolicy,
  getPolicy,
} from "../../services/StaticContent/StaticContentService";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function StaticPages() {
  const [type, setType] = useState("aboutUs");
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState("");
//   console.log(content, "content");
  const getData = async () => {
    setLoader(true);
    try {
      const response = await getPolicy(type);
      setData(response.data.data);
    //   console.log(response.data.data, "data");

      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  const onPostContent = async () => {
    // setLoader(true);
    try {
      const response = await createPolicy(type, content);
      notifyTopRight("Created Successfully.");

      //   setLoader(false);
    } catch (error) {
      notifyError(error.response.data?.message);
      console.log(error, "error");
      //   setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [type]);
  return (
    <div>
      <PageTitle
        activeMenu="Static Pages Content"
        motherMenu="Static Pages Content"
      />
      <div className="d-flex justify-content-end">
        <div></div>
        <select
          value={type}
          className="form-control"
          style={{ width: "200px" }}
          onChange={(e) => setType(e.target.value)}
        >
          <option hidden>Select type</option>
          <option value={"aboutUs"}>About Us</option>
          <option value={"privacyPolicy"}>Privacy Policy</option>
          <option value={"termsAndCondition"}>Terms And Conditions</option>
        </select>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Content</h4>
            </div>
            <div className="card-body custom-ekeditor">
              {/* <h2>Using CKEditor 5 build in React</h2> */}
              {loader ? (
                <Spinner />
              ) : (
                <CKEditor
                  editor={ClassicEditor}
                  data={data}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                    // console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              )}
            </div>
            <div className="card-footer text-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onPostContent()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
