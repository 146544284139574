
import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Accordion, Card, Col } from "react-bootstrap";
import Spinner from "../components/Common/Spinner";

import { useLocation } from "react-router-dom";

import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import { deleteFaq, getFAQSList } from "../../services/User/UserService";
import AddFaq from "../components/Modal/AddFaq";
import EditFaq from "../components/Modal/EditFaq";

export default function FAQsList() {
  const [isDeleteHovered, setDeleteHovered] = useState("");
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const limit = 10;
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [oneData, setOneData] = useState(null);
  const location = useLocation();

  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getFAQSList(
        currentPage,
        limit,
      );
      console.log(response, "Fetched FAQs");

      // Access the nested faqs array
      const faqsData = response.data?.data?.faqs || [];
      setData(faqsData);

      setLoader(false);
      const total = response.data?.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data?.data?.count);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      setLoader(false);
    }
  };

  const handleDelete = async (id) => {
    setLoader(true);
    // console.log(first)
    try {
      const response = await deleteFaq(id);
      handleFetch();
      notifyTopRight("Deleted Successfully!");
      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
      setLoader(false);
    }
  };

  const handleEdit = async (data) => {
    setOneData(data);
    setShowEditModal(true);
  };

  useEffect(() => {
    handleFetch();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="FAQs List" motherMenu="FAQs" />
      <Col>
        <Card>
          <Card.Header className="d-flex">
            <Card.Title>FAQs</Card.Title>
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() => setShowAddModal(true)}
            >
              Add +
            </button>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Accordion>
                {data && data?.length > 0 ? (
                  data?.map((item, i) => (
                    <Accordion.Item
                      className="accordion-item"
                      eventKey={i.toString()}
                      key={i}
                    >
                      <Accordion.Header className="accordion-header rounded-lg">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <span>{item?.question}</span>
                          <div>
                            <i
                              className="flaticon-381-edit-1 mx-2"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion toggle
                                handleEdit(item);
                              }}
                            ></i>
                            <i
                              className="flaticon-381-trash-1 mx-2 pe-3"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion toggle
                                handleDelete(item?._id);
                              }}
                            ></i>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{item?.answer}</Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <div className="justify-content-center d-flex my-5">
                    Sorry, Data Not Found!
                  </div>
                )}
              </Accordion>
            )}
          </Card.Body>
        </Card>
      </Col>
      {showAddModal && (
        <AddFaq
          show={showAddModal}
          table={handleFetch}
          onHide={() => setShowAddModal(false)}
        />
      )}
      {showEditModal && (
        <EditFaq
          show={showEditModal}
          table={handleFetch}
          data={oneData}
          faqId={data?._id}
          onHide={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}
