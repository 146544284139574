import React, { useEffect, useState } from "react";

import { notifyError, notifyTopRight } from "../Common/Toaster";
import { Modal } from "react-bootstrap";
import { editFaq } from "../../../services/User/UserService";

export default function EditFaq({ show, data,table, onHide }) {
  let errorsObj = { question: "", answer: "" };

  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    faqId: "",
  });

  const [loader, setLoader] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.question.trim() === "") {
      errorObj.question = "This fiels is required";
      error = true;
    }
    if (formData?.answer.trim() === "") {
      errorObj.answer = "This fiels is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await editFaq(formData);
      notifyTopRight(response?.data?.message);
      setFormData({ question: "", answer: "", });
      table();
      onHide();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  useEffect(() => {
    setFormData({
      question: data?.question,
      answer: data?.answer,
      faqId: data?._id,
    });
  }, [data]);
  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={onSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Edit Details</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Question</label>
            <input
              className="form-control"
              name="question"
              value={formData.question}
              onChange={handleInput}
            />
            {errors.question && (
              <div className="text-danger fs-12">{errors.question}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Answer</label>
            <input
              className="form-control"
              name="answer"
              value={formData.answer}
              onChange={handleInput}
            />
            {errors.answer && (
              <div className="text-danger fs-12">{errors.answer}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-secondary py-2 px-3 fs-14">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary py-2 px-3 fs-14">
            Update
          </button>
        </div>
      </form>
    </Modal>
  );
}
