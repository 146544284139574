import axiosInstance from "../AxiosInstance";
import { CREATE_POLICY, POLICY } from "./EndPoints";

export function getPolicy(type) {
  return axiosInstance.get(POLICY + `?type=${type}`);
}

export function createPolicy(type, content) {
  const postData = { type: type, content: content };
  return axiosInstance.post(CREATE_POLICY, postData);
}
