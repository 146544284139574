export const USERLIST = "admin/mentorList";

export const USER_ACTION = "admin/userAction";

export const USERLIST_MENTEE = "admin/menteList";

export const FAQS = "admin/faqs";

export const CREATE_FAQS = "admin/createFaq";

export const FAQ_EDIT = "admin/editFaq";

export const FAQ_DELETE = "admin/deleteFaq";
